//images.tsx

export const GCS_URL = process.env.REACT_APP_GCS_URL;

export const imagesArray = [
    "/images/bg_main.webp",
    "/images/bg_secondary.webp",
    "/images/bg_wide.webp",
    "/images/scissors.webp",
    "/images/cheat_alarm.webp",
    "/images/cheat_continue.webp",
    "/images/ropes.webp",
    "/images/ropes_short.webp",
    "/images/ropes_wide.webp",
    "/images/game_progress_panel_bot.webp",
    "/images/game_energy.webp",
    "/images/game_progress_panel_top.webp",
    "/images/loading_title.webp",
    "/images/loading_sheep.webp",
    "/images/btn_checked.webp",
    "/images/no_connection_bg.webp",
    "/images/no_connection_wifi.webp",
    "/images/no_task_channel_btn.webp",
    "/images/no_task_channel_modal.webp",
    "/images/channel_base.webp",
    "/images/tech_works.webp",
    "/images/tech_clock.webp",

    "/images/frens/invite_friends_btn.webp",
    "/images/frens/layer.webp",
    "/images/frens/frens_list_base.webp",
    "/images/frens/refer_base.webp",
    "/images/frens/frens_invited_base.webp",
    "/images/frens/frens_earned_base.webp",
    "/images/frens/copy_btn.webp",

    "/images/modalTasksContent/btn_close.webp",
    "/images/modalTasksContent/base.webp",
    "/images/modalTasksContent/btn_blue.webp",
    "/images/modalTasksContent/divider.webp",

    "/images/about/about_bg.webp",
    "/images/about/about_sheep.webp",
    "/images/about/about_title.webp",

    "/images/tasks/tg.webp",
    "/images/tasks/twitter.webp",
    "/images/tasks/panel.webp",
    "/images/tasks/ava_panel.webp",
    "/images/tasks/play.webp",

    "/images/boost/boost_btn_claim.webp",
    "/images/boost/boost_btn_go.webp",
    "/images/boost/energy.webp",
    "/images/boost/progress_panel.webp",
    "/images/boost/progress_complete.webp",

    "/images/tabbar/Layer_6.webp",
    "/images/tabbar/ic_tabbar_frens_act.webp",
    "/images/tabbar/ic_tabbar_earn_def.webp",
    "/images/tabbar/ic_tabbar_game_act.webp",
    "/images/tabbar/ic_tabbar_boost_def.webp",
    "/images/tabbar/ic_tabbar_rating_def.webp",


    "/images/header/wooden_plate.webp",
    "/images/header/paper.webp",
    "/images/header/coin_main.webp",
    "/images/header/ic_sound.webp",
    "/images/header/ic_sound_off.webp",
    "/images/header/coins_base.webp",
    "/images/header/ic_info.webp",

    "/images/leaders/cup_gold.webp",
    "/images/leaders/cup_silver.webp",
    "/images/leaders/cup_bronze.webp",
    "/images/leaders/reward_panel.webp",
    "/images/leaders/diamond_token.webp",
    "/images/leaders/confetti.webp",
    "/images/leaders/olive.webp",
    "/images/leaders/crown.webp",

    "/images/keypad/base.webp",
    "/images/keypad/backspace.webp",
    "/images/keypad/clock.webp",
    "/images/keypad/divider.webp",
    "/images/keypad/enter.webp",
    "/images/keypad/footer.webp",
    "/images/keypad/heart.webp",
    "/images/keypad/heart_opacity.webp",
    "/images/keypad/slot.webp",
    "/images/keypad/slot_blue.webp",
    "/images/keypad/red.webp",
    "/images/keypad/pressed.webp",
    "/images/keypad/safe.webp",
    "/images/keypad/sheep.webp",
    "/images/keypad/shine.webp",
    "/images/keypad/unpressed.webp",
];
