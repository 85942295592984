import s from "./FrensList.module.css"
import { User } from "../../redux/types";
import {GCS_URL} from "../../services/images";


const FrensListItem: React.FC<{ item: User, index: number, arrLength: number }> = ({ item, index, arrLength }) => {

    const getListItemClassNamePos = (index: number) => {
        switch (index) {
            case 0:
                return s.goldPos;
            case 1:
                return s.silverPos;
            case 2:
                return s.bronzePos;
            default:
        }

        if (index >= 9999) {
            return s.over10000;
        }

        if (index >= 999) {
            return s.over1000;
        }

        if (index >= 99) {
            return s.over100;
        }

        return s.lowRatingPos;
    };


    return (
        <div className={s.listItemBox}>
            {index < arrLength - 1 && <img className={s.ropesBG} style={{ animationDelay: `${index * 0.01}s` }} src={`/images/ropes.webp`} alt="ropes" />}

            <div className={s.listItem} > {/*style={{ animationDelay: `${index * 0.01}s` }}*/}
                <div className={s.friend}>
                    <div className={`${s.position} ${getListItemClassNamePos(index)}`}>{index + 1}</div>
                    <img className={s.avatarImg} src={item.avatar? GCS_URL + item.avatar: `/images/header/coin_main.webp`} alt="avatar" />
                    <p className={s.friendName}>{item.username? item.username: 'N/A'}</p>
                </div>
                {item.ref_level !== 0 &&<div className={s.score}>
                    <img className={s.scoreImg} src={`/images/header/coin_main.webp`} alt="coin" />
                    <p className={s.scoreText}>{item.tokens} baa</p>
                </div>}
            </div>
        </div>
    )
}

export default FrensListItem