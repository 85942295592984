import s from './ModalStartTasksContent.module.css'

interface ProgressBarProps {
    total: number;
    done: number;
}

const ProgressBarStartTasks = ({total, done}: ProgressBarProps) => {

    return (
        <div className={s.progressContainer}>
            <div className={s.progressBar}>
                <div className={s.progressBarStroke}></div>


                {Array.from({ length: total }).map((_, index) => {
                    const isFirst = index === 0;
                    const isLast = index === total - 1;

                    return (
                        <div
                            key={index}
                            className={`${index < done ? s.completed : s.segment}
                            ${isFirst ? s.firstSegment : ""} 
                            ${isLast ? s.lastSegment : ""}`}
                        >
                            <img
                                className={s.coinImgBar}
                                style={{ opacity: index < done ? 1 : 0.25 }}
                                src={'/images/startTasks/coin_blue.webp'} alt="coin" />
                        </div>
                    );
                })}
            </div>
        </div>
    )
}

export default ProgressBarStartTasks