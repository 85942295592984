// src/redux/sagas/gameSaga.ts
import {call, put, Effect, debounce, delay, select, takeLatest, takeEvery, fork, throttle} from 'redux-saga/effects';
import {
    resetTaps,
    restoreTaps,
    setAuthorization,
    setAvatar,
    setBoostStatus,
    setCompletedTasks,
    setEnergy,
    /*setFriends,*/
    setMaxEnergy,
    setPosition,
    setTaskStatus,
    setAddress,
    setAuthTokens,
    setUser,
    setTokens,
    setLastSyncAt,
    showPromo,
    setPromoAttempts,
    setClaimedPromos,
    setAutoTapSeconds,
    increaseTokens,
    decreaseEnergy, setAppLanguage,
} from '../slices/gameSlice';
import {PayloadAction} from "@reduxjs/toolkit";
import {apiCaller} from "./apiCaller";
import {RootState} from "../store";
import telegramWebAppAPI from "../../services/telegram";
import {User} from "../types";
import {i18n} from "@lingui/core";


function* handleAuthorize(action: PayloadAction<string>): Generator<any, void, any> {
    try {
        const tokens = yield call(apiCaller, { apiFuncName: 'authorize', args: action.payload});
        yield put(setAuthTokens(tokens));
        yield put(setAuthorization(true));
        // After successful authorization, fetch the user
        yield put({type: 'tasks/fetchTasks'});
        yield put({ type: 'game/setLoadProgress', payload: 20 });
        yield delay(400);

        yield put({type: 'boost/fetchBoosts'});
        yield put({ type: 'game/setLoadProgress', payload: 40 });
        yield delay(400);

        yield put({type: 'promo/fetchPromo'});
        yield put({ type: 'game/setLoadProgress', payload: 60 });
        yield delay(400);

        yield put({type: 'leaderboard/fetchLeaderboard'});
        yield put({ type: 'game/setLoadProgress', payload: 80 });
        yield delay(400);

        yield put({ type: 'game/fetchUser' });
        yield put({ type: 'game/setLoadProgress', payload: 100 });
        yield delay(400);
    } catch (error) {
        console.error(error);
        yield put(setAuthorization(false));
    }
}

function* handleFetchUser(): Generator<any, void, any> {
    try {
        const {user} = yield call(apiCaller, { apiFuncName: 'fetchProfile', args: {}});

        let app_lang = "en";
        if(!user.app_language && user.language_code === "ru") {
            app_lang = "ru";
            user.app_language = app_lang;
        }

        yield put(setUser(user));

        // set default user language
        if(user.app_language) i18n.activate(user.app_language);

        // yield put(setFriends(friends));
        yield put({type: 'game/setIsLoaded', payload: true});
    } catch (error) {
        console.error(error);
    }
}

function* blockExit(): Generator<any, void, any> {
    const confirm = yield call(telegramWebAppAPI.isClosingConfirmationEnabled);
    if(!confirm)
        yield call(telegramWebAppAPI.enableClosingConfirmation);
}

function* sync(): Generator<any, void, any> {
    const game = yield select((state: RootState) => state.game);
    // we either have taps or last sync was more that 1 minute ago
    if((game.taps.length === 0 && (new Date().getTime() - game.lastSyncAt < 60*1000)) || game.isNetworkError) return;

    const taps = game.taps;
    try {
        yield put(resetTaps());
        const {user} = yield call(apiCaller, { apiFuncName: 'updateProfile', args: {taps: taps, energy: game.user.energy}});
        yield call(applyUpdates, {user})
    } catch (error) {
        console.error(error);
        yield put(restoreTaps(taps));
    }

    yield put(setLastSyncAt(new Date().getTime()));

    const confirm = yield call(telegramWebAppAPI.isClosingConfirmationEnabled);
    if(confirm)
        yield call(telegramWebAppAPI.disableClosingConfirmation);
}

export function *fetchUpdates(): Generator<any, void, any> {
    try {
        const {user} = yield call(apiCaller, { apiFuncName: 'fetchProfile', args: {}});
        yield call(applyUpdates, {user})
    } catch (e) {
        console.error(e);
    }
}

interface ApplyUpdatesArgs {
    user: User
    // friends: User[];
}

// apply update to user in store
function *applyUpdates({user}: ApplyUpdatesArgs): Generator<any, void, any> {
    // yield put(setFriends(friends));

    yield put(setAddress(user.airdrop_address));
    yield put(setAppLanguage(user.app_language || 'en'));
    yield put(setMaxEnergy(user.max_energy));
    yield put(setAvatar(user.avatar));
    yield put(setTaskStatus(user.tasks));
    yield put(setBoostStatus(user.boosts));
    yield put(setCompletedTasks(user.completed_tasks));
    yield put(setPosition(user.position));
    yield put(setPromoAttempts(user.promo_attempts));
    yield put(setClaimedPromos(user.promos_claimed));
    yield put(setAutoTapSeconds(user.auto_tap_seconds || 0));

    // set tokens/energy logic
    const innerTokens = yield select((state: RootState) => state.game.user.tokens);
    const taps = yield select((state: RootState) => state.game.taps);
    const tapPower = yield select((state: RootState) => state.game.user.tap_power || 1);
    const tapsCount = taps.length;


    const newTokens = user.tokens + (tapsCount * tapPower);
    const newEnergy = user.energy - (tapsCount * tapPower);



    if (newTokens >= innerTokens && newEnergy > 0) {
        yield put(setTokens(newTokens));
        yield put(setEnergy(newEnergy));
    }
}

export function* every5Minutes() {
    try {
        yield delay(60000 * 5);
        yield put({type: 'leaderboard/fetchLeaderboard'});
        yield put({ type: "game/every5Minutes" })
    } catch (e) {
        console.error(e);
    }
}

// New combined logic for debounce with timeout
function* syncTimeout(): Generator<any, void, any> {
    while (true) {
        try {
            yield delay(20000);
            yield call(sync);
        } catch (e) {
            console.error(e);
        }
    }
}

function* autoTapSimulation(): Generator<any, void, any> {
    while (true) {
        yield delay(1000);
        const autoTapSeconds = yield select((state: RootState) => state.game.user.auto_tap_seconds);
        if(autoTapSeconds > 0) {
            const tapPower = yield select((state: RootState) => state.game.user.tap_power || 1);
            const currentEnergy = yield select((state: RootState) => state.game.user.energy);

            if(currentEnergy >= tapPower) {
                yield put(increaseTokens(tapPower));
                yield put(decreaseEnergy(tapPower));
            }
        }
    }
}

function* resetSecret(): Generator<any, void, any> {
    yield delay(1000);
    const showPromoValue = yield select((state: RootState) => state.game.showPromo);
    if(showPromoValue >= 5) return;

    yield put(showPromo(0));
}

function *addTaps(): Generator<any, void, any> {
    yield put({type: 'game/setTaps', payload: 1})
}

function *setAirdropAddress(action: PayloadAction<string>): Generator<any, void, any> {
    const {user} = yield call(apiCaller, { apiFuncName: 'setAddress', args: {address: action.payload}});
    yield call(applyUpdates, {user})
}

function *setLanguageCode(action: PayloadAction<string>): Generator<any, void, any> {
    const {user} = yield call(apiCaller, { apiFuncName: 'setLanguage', args: {language: action.payload}});
    yield call(applyUpdates, {user})
}

export default function* gameSaga(): Generator<Effect, void, unknown> {
    yield takeLatest('game/authorize', handleAuthorize);
    yield takeLatest('game/fetchUser', handleFetchUser);
    yield takeEvery('game/addTaps', blockExit);
    yield takeEvery('game/setAirdropAddress', setAirdropAddress);
    yield takeEvery('game/setLanguageCode', setLanguageCode);
    yield takeLatest('game/showPromo', resetSecret);
    yield throttle(100, 'game/addTaps', addTaps);
    yield debounce(1000, 'game/setTaps', sync);
    yield fork(syncTimeout);
    yield fork(autoTapSimulation);
    yield takeLatest('game/every5Minutes', every5Minutes)
}
