// src/index.tsx
import React from 'react';
import * as ReactDOM from "react-dom/client";
import { Provider } from 'react-redux';
import store from './redux/store';
import App from './App';
import './styles.css';
import { BrowserView, MobileView } from 'react-device-detect';
import ModalQRContent from "./components/ModalQRContent";
import * as Sentry from "@sentry/react";

import { i18n } from "@lingui/core";
import { I18nProvider } from "@lingui/react";

import { messages as enMessages } from "./locales/en/messages";
import { messages as ruMessages } from "./locales/ru/messages";

i18n.load({
    en: enMessages,
    ru: ruMessages,
});
i18n.activate("en");


const sentryDsn = process.env.REACT_APP_SENTRY_DSN;
if(sentryDsn) {
    const sentrySampleRate = process.env.REACT_APP_SENTRY_SAMPLE_RATE?? 1;
    Sentry.init({
        dsn: sentryDsn,
        integrations: [
            Sentry.browserTracingIntegration(),
            Sentry.replayIntegration(),
        ],
        // Performance Monitoring
        tracesSampleRate: Number(sentrySampleRate), //  Capture 100% of the transactions
        // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
        // tracePropagationTargets: ["localhost", /^https:\/\/baa\.best\/api/],
        // Session Replay
        replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
        replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
    });
}


const AppWrapper: React.FC = () => {
    return <App />;
};

// todo: enable <React.StrictMode> to locate bugs

ReactDOM.createRoot(document.getElementById("root")!).render(
    // <React.StrictMode>
        <Provider store={store}>
            <I18nProvider i18n={i18n}>
            <MobileView>
                <AppWrapper />
            </MobileView>
            <BrowserView>
                <ModalQRContent />
            </BrowserView>
            </I18nProvider>
        </Provider>
    // </React.StrictMode>
);

