import ItemListBG from '../common/ItemListBG';
import PageHeader from '../common/PageHeader';
import SimpleButton from '../common/SimpleButton';
import s from './ModalBoostBuyingContent.module.css'
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import { Boost, BoostType } from "../../redux/types";
import telegramWebAppAPI from "../../services/telegram";
import { showToast } from "../../redux/slices/gameSlice";
import { Trans } from "@lingui/react/macro";

interface ModalBoostBuyingProps {
    closeModal: () => void;
    title: string;
    description: string
}

const ModalBoostBuyingContent: React.FC<ModalBoostBuyingProps> = ({ closeModal, title, description }) => {
    const { boosts } = useSelector((state: RootState) => state.boost);
    const pageBoosts = boosts.filter((el) => el.type === BoostType.TOKENS)
    const dispatch = useDispatch();

    const purchaseBoost = (boost: Boost) => {
        if (!boost.invoice_url) return;

        telegramWebAppAPI.openInvoice(boost.invoice_url, (res: string) => {
            if (res === 'paid') {
                // todo: need to check if transaction already completed
                dispatch(showToast('Purchase successful!'));
                setTimeout(() => {
                    dispatch({ type: 'game/fetchUser' });
                }, 1000);
            }
        });

    }

    return (
        <div className={s.container}>
            <img className={s.imgConfetti} src={"/images/leaders/leaders_confetti.webp"} alt="confetti" />

            <div className={s.btnClose}>
                <SimpleButton imageUrl={`/images/modalTasksContent/btn_close.webp`} width={8.89} onClick={closeModal} />
            </div>

            <PageHeader title={title} fontSize={1} />

            <div className={s.starBox}>
                <img className={s.starImg} src={"/images/leaders/star.webp"} alt="star" />
                <p className={s.text}>{description}</p>
            </div>

            <div className={s.scrollContainer}>
                {pageBoosts.map((item, index) => (
                    <ItemListBG key={index} size={"small"} index={index} length={pageBoosts.length}>
                        <div className={s.listItemContainer}>

                            <img className={s.avaImg} src="/images/header/coin_main.webp" alt="ava" />


                            <div className={s.priceContainer}>
                                <p className={s.textList}>{item.reward}</p>
                            </div>

                            {item.old_price !== 0 && <div className={s.oldPriceBox}>
                                <p className={s.textOld}><Trans>old price</Trans></p>
                                <div className={s.diagonalStrike}>
                                    <span className={s.textOldPrice}>{item.old_price}</span>
                                </div>
                            </div>}

                            <SimpleButton className={s.starsBtn} onClick={() => {
                                purchaseBoost(item);
                            }} type="button" imageUrl={`/images/list_btn.webp`}>
                                <img className={s.btnStarImg} src={"/images/leaders/star.webp"} alt="star" />
                                <p className={s.textList}>{item.price}</p>
                            </SimpleButton>
                        </div>
                    </ItemListBG>
                ))}
            </div>

        </div>
    )
}

export default ModalBoostBuyingContent