// src/pages/AirdropPage.tsx
import { useDispatch, useSelector } from "react-redux";
import { toggleLeaderboard } from "../../redux/slices/leaderboardSlice";
import { RootState } from "../../redux/store";
import LeadersList from '../../components/LeadersList';
import PageContainer from "../../components/common/PageContainer";
import s from './LeaderboardPage.module.css';
import { useEffect, useState } from "react";
import { Trans } from "@lingui/react/macro";
import {i18n} from "@lingui/core";

const LeaderboardPage: React.FC = () => {
    const { leaderboard, weeklyLeaderboard } = useSelector((state: RootState) => state.leaderboard);
    const { user } = useSelector((state: RootState) => state.game);
    const selectedLanguage = useSelector((state: RootState) => state.game.user.app_language || 'en');

    const dispatch = useDispatch();
    const isFriendsLeaderboard = useSelector((state: RootState) => state.leaderboard.isFriendsLeaderboard);
    const toggle = () => dispatch(toggleLeaderboard())

    const { totalUsers, timeLeft } = useSelector((state: RootState) => state.leaderboard);

    const [localTimer, setLocalTimer] = useState(timeLeft);
    const [daysLeft, setDaysLeft] = useState('0');
    const [hoursLeft, setHoursLeft] = useState('00');
    const [minutesLeft, setMinutesLeft] = useState('00');
    const [secondsLeft, setSecondsLeft] = useState('00');

    useEffect(() => {
        setDaysLeft(String(Math.floor(localTimer / 86400))); // Calculate days
        setHoursLeft(String(Math.floor((localTimer % 86400) / 3600)).padStart(2, '0')); // Remaining hours after days
        setMinutesLeft(String(Math.floor((localTimer % 3600) / 60)).padStart(2, '0')); // Remaining minutes after hours
        setSecondsLeft(String(localTimer % 60).padStart(2, '0')); // Remaining seconds

        const interval = setInterval(() => {
            setLocalTimer(localTimer - 1);
        }, 1000);

        return () => clearInterval(interval);
    }, [localTimer]);


    const startDate = new Date();
    const endDate = new Date("2025-07-01T00:00:00Z");

    const diffMilliseconds = endDate.getTime() - startDate.getTime();
    const diffSeconds = Math.round(diffMilliseconds / 1000);

    const [globalTimer, setGlobalTimer] = useState(diffSeconds);
    const [gdaysLeft, setgDaysLeft] = useState('0');
    const [ghoursLeft, setgHoursLeft] = useState('00');
    const [gminutesLeft, setgMinutesLeft] = useState('00');
    const [gsecondsLeft, setgSecondsLeft] = useState('00');

    useEffect(() => {
        setgDaysLeft(String(Math.floor(globalTimer / 86400))); // Calculate days
        setgHoursLeft(String(Math.floor((globalTimer % 86400) / 3600)).padStart(2, '0')); // Remaining hours after days
        setgMinutesLeft(String(Math.floor((globalTimer % 3600) / 60)).padStart(2, '0')); // Remaining minutes after hours
        setgSecondsLeft(String(globalTimer % 60).padStart(2, '0')); // Remaining seconds

        const interval = setInterval(() => {
            setGlobalTimer(globalTimer - 1);
        }, 1000);

        return () => clearInterval(interval);
    }, [globalTimer]);

    const imagePath = selectedLanguage === "en"
        ? !isFriendsLeaderboard
            ? "/images/leaders/ldb_500_en.webp"
            : "/images/leaders/ldb_7_en.webp"
        : selectedLanguage === "ru"
            ? !isFriendsLeaderboard
                ? "/images/leaders/ldb_500_ru.webp"
                : "/images/leaders/ldb_7_ru.webp"
            : "/images/leaders/leaders_text_top500.webp"

    const dayTranslation = i18n.t('d');

    return (
        <PageContainer>
            <button className={s.toggleBtn}
                style={{ justifyContent: `${!isFriendsLeaderboard ? "flex-start" : "flex-end"}` }}
                onClick={toggle} >
                <div className={!isFriendsLeaderboard ? s.buttonBg : s.buttonNoBg}>
                    <p className={s.toggleBtnText}><Trans>Top 500</Trans></p>
                </div>
                <div className={!isFriendsLeaderboard ? s.buttonNoBg : s.buttonBg}>
                    <p className={s.toggleBtnText}><Trans>7 days</Trans></p>
                </div>

                <img className={s.sheepImg} src={!isFriendsLeaderboard ? "/images/leaders/leaders_sheep_7days.webp" : "/images/leaders/leaders_sheep_top500.webp"} alt="sheep" />
            </button>

            <img className={s.heroImg} src={imagePath} alt="sheep" />

            <div className={s.headerContainer}>
                <div className={s.titleOnBaseContainer}>
                    <div className={s.titleOnBase}>
                        <p className={s.titleOnBaseText}><Trans>Rank</Trans></p>
                    </div>

                    <div className={s.titleCounterBox}>
                        <p className={s.titleCounter}>{!isFriendsLeaderboard ? <Trans>time left:</Trans> : <Trans>time left:</Trans>}</p>
                        <p className={s.text}>{!isFriendsLeaderboard ? `${gdaysLeft}${dayTranslation} ${ghoursLeft}:${gminutesLeft}:${gsecondsLeft}` : `${daysLeft}${dayTranslation} ${hoursLeft}:${minutesLeft}:${secondsLeft}`}</p>
                    </div>

                    <div className={s.titleOnBase}>
                        <p className={s.titleOnBaseText}><Trans>Prizes</Trans></p>
                    </div>
                </div>
            </div>

            <LeadersList arrayItems={isFriendsLeaderboard ? weeklyLeaderboard : leaderboard} isWeekly={isFriendsLeaderboard} currentUser={user} />

        </PageContainer>
    );
};

export default LeaderboardPage;
