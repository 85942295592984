import PageHeader from '../common/PageHeader';
import SimpleButton from '../common/SimpleButton';
import s from './ModalRulesContent.module.css'
import { Trans } from "@lingui/react/macro";
import { t } from "@lingui/core/macro";
import {useSelector} from "react-redux";
import {RootState} from "../../redux/store";
import {useEffect} from "react";

interface ModalRulesContentProps {
    closeModal: () => void;
}

interface Reward {
    title: string,
    value: string
}

function transformRewards(rewards: {[key:number]:number}) {
    const entries = Object.entries(rewards)
        .map(([key, value]) => ({ key: Number(key), value }))
        .sort((a, b) => a.key - b.key);

    const result = [];
    let rangeStart = entries[0].key;
    let prevValue = entries[0].value;

    for (let i = 1; i < entries.length; i++) {
        const { key, value } = entries[i];

        if (value !== prevValue) {
            result.push({
                title: rangeStart === entries[i - 1].key ? `${rangeStart}` : `${rangeStart}-${entries[i - 1].key}`,
                value: `$${prevValue}`,
            });
            rangeStart = key;
        }
        prevValue = value;
    }

    result.push({
        title: rangeStart === entries[entries.length - 1].key ? `${rangeStart}` : `${rangeStart}-${entries[entries.length - 1].key}`,
        value: `$${prevValue}`,
    });

    return result;
}

const ModalRulesContent: React.FC<ModalRulesContentProps> = ({ closeModal }) => {
    const { rewards } = useSelector((state: RootState) => state.leaderboard);
    let groupedRewards: Reward[] = transformRewards(rewards);

    return (
        <div className={s.container}>
            <div className={s.btnClose}>
                <SimpleButton imageUrl={`/images/modalTasksContent/btn_close.webp`} width={8.89} onClick={closeModal} />
            </div>
            <img className={s.imgConfetti} src={"/images/leaders/confetti.webp"} alt="confetti" />
            <PageHeader title={t`Rules`} fontSize={1.25} />

            <div className={s.contentContainer}>
                <img className={s.imgCup} src={"/images/header/rules_cup.webp"} alt="cup" />

                <div className={s.textBox}>
                    <div className={s.gradientBox}>
                        <p className={`${s.textShadow} ${s.text15rem}`}>
                            🏆 <Trans>top 500 leaderbord</Trans></p>
                        <p className={`${s.textGradient} ${s.text15rem}`}>
                            🏆 <Trans>top 500 leaderbord</Trans></p>
                    </div>
                    <p className={s.text}><Trans>Prize Pool: $30,000 in USDT + $BAA tokens</Trans></p>
                    <p className={s.text065}><Trans>This leaderboard counts all your actions in the game! Shear the Black Sheep, invite friends, complete tasks and buy xBAA coins to increase your rank.</Trans></p>
                    <p className={s.text055}>🗓 <Trans>The date of the reward distribution will be announced very soon!</Trans></p>
                </div>

                <div className={s.textBox}>
                    <div className={s.gradientBox}>
                        <p className={`${s.textShadow} ${s.text15rem}`}>
                            🏆 <Trans>7 DAYS Leaderboard:</Trans></p>
                        <p className={`${s.textGradient} ${s.text15rem}`}>
                            🏆 <Trans>7 DAYS Leaderboard:</Trans></p>
                    </div>
                    <p className={s.text065}><Trans>This leaderboard only counts stats from invited referrals.</Trans></p>
                    <p className={s.text065}><Trans>Participate in a weekly competition with a prize pool of $1400! Invite more friends and get paid to your TON address every week if you make it to the TOP-25!</Trans></p>

                    <div className={s.table}>
                        {groupedRewards.map((item, index) => (
                            <div key={index} className={s.cell}>
                                <div className={s.gradientBox}>
                                    <p className={`${s.textShadow} ${s.text05rem}`}>
                                        {item.title} <Trans>place</Trans></p>
                                    <p className={`${s.textGradient} ${s.text05rem}`}>
                                        {item.title} <Trans>place</Trans></p>
                                </div>
                                <p className={s.text065}><Trans>{item.value}</Trans>!</p>
                            </div>
                        ))}
                    </div>
                    <p className={s.text04}><Trans>*The race starts at 20:00 UTC every Wednesday and ends at 20:00 UTC exactly one week later.</Trans></p>
                    <p className={s.text065}><Trans>Hurry up, you only have 7 DAYS!</Trans></p>
                </div>

                <div className={s.textBox}>
                    <p className={s.text065}>📣 <Trans>Don't forget to save the address of your TON wallet that supports USDT and other tokens on the TON network (click on the AIRDROP icon on the main game screen).</Trans></p>
                </div>

                <div className={s.textBox}>
                    <p className={s.text}>⚠️ <Trans>ATTENTION</Trans>!</p>
                    <p className={s.text065}><Trans>Only active referrals are counted. We strongly discourage the use of bots or fake pages, - unscrupulous participants will be banned for the use of scrambling</Trans>!</p>
                </div>
            </div>
        </div>
    )
}

export default ModalRulesContent