import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import ListItem from './ListItem';
import { Trans } from "@lingui/react/macro";
import ProgressBarStartTasks from './ProgressBarStartTasks';
import s from './ModalStartTasksContent.module.css'
import { useEffect, useRef, useState } from "react";
import { Task } from "../../redux/types";
import telegramWebAppAPI from "../../services/telegram";


interface ModalStartTasksContentProps {
    closeModal: () => void;
    onClick: () => void;
}

const channels = [
    "$Baa Meme",
    "Cryptolov",
    "Crypto Angelok",
    "X-Hunter Calls",
    "Crypto Jew",
    "Fomo Radar",
    "Cryptonite"
]

const ModalStartTasksContent: React.FC<ModalStartTasksContentProps> = ({ closeModal, onClick }) => {
    const dispatch = useDispatch();
    const { tasks } = useSelector((state: RootState) => state.tasks);
    const { user } = useSelector((state: RootState) => state.game);

    const taskStatus = new Map<number, number>();

    if (user.completed_tasks) {
        user.completed_tasks.forEach((taskId) => {
            taskStatus.set(taskId, taskId);
        })
    }

    const newTasks = tasks.filter((el) => !taskStatus.has(el.id));
    const doneTasks = tasks.filter((el) => taskStatus.has(el.id));

    let topTasksList = tasks.slice(0, 3);
    let botTasksList = tasks.slice(3);
    const timeoutRef = useRef<NodeJS.Timeout | null>(null);

    const runningRequest = useSelector((state: RootState) => state.game.runningRequest);
    const [isTaskClicked, setIsTaskClicked] = useState<Map<number, boolean>>(new Map<number, boolean>);
    const [anyTaskClicked, setAnyTaskClicked] = useState<boolean>(false);

    useEffect(() => {
        let isClicked = false;
        isTaskClicked.forEach((val) => {if(val) isClicked = true})
        setAnyTaskClicked(isClicked);
    }, [isTaskClicked]);

    useEffect(() => {
        if (!runningRequest) {
            setIsTaskClicked(prevMap => {
                const newMap = new Map();
                tasks.forEach((t) => newMap.set(t.id, false));
                return newMap;
            })
        }
    }, [runningRequest]);

    const handleButtonClick = (task: Task) => {
        setIsTaskClicked(prevMap => {
            const newMap = new Map(prevMap);
            newMap.set(task.id, true);
            return newMap;
        })

        telegramWebAppAPI.openTelegramLink(task.url);

        // Store timeout reference
        timeoutRef.current = setTimeout(() => {
            dispatch({ type: 'tasks/checkAndMarkTask', payload: task.id });
        }, 3000);

    };

    // Cleanup timeout when component unmounts
    useEffect(() => {
        return () => {
            if (timeoutRef.current) {
                clearTimeout(timeoutRef.current);
            }
        };
    }, []);



    const selectedLanguage = useSelector((state: RootState) => state.game.user.app_language || 'en');
    const imagePath = selectedLanguage === "en"
        ? "/images/startTasks/start_tasks_bg_circle.webp"
        : "/images/startTasks/start_tasks_bg_circle_ru.webp"

    const completedAllTasks = newTasks.length == 0

    return (
        <div className={s.container} style={{ backgroundImage: `url(${imagePath})` }}>

            <div className={`${s.listContainer} ${s.listContainerUp}`}>
                {topTasksList.map((task, index) => (
                    <ListItem key={index} title={channels[task.id - 1]} fontSize={0.75} isJoin={taskStatus.has(task.id)} btnSize={"btnSizeTop"} imgBG={"itemBGTop"} isClicked={isTaskClicked.has(task.id) && isTaskClicked.get(task.id) === true} onClick={() => handleButtonClick(task)} isDisabled={anyTaskClicked} />
                ))}
            </div>

            <div className={`${s.listContainer} ${s.listContainerDown}`}>
                {botTasksList.map((task, index) => (
                    <ListItem key={index} title={channels[task.id - 1]} fontSize={0.5625} isJoin={taskStatus.has(task.id)} btnSize={"btnSizeBot"} imgBG={"itemBGBot"} isClicked={isTaskClicked.has(task.id) && isTaskClicked.get(task.id) === true} onClick={() => handleButtonClick(task)} isDisabled={anyTaskClicked} />
                ))}
            </div>


            <div className={s.barContainer}>
                <div className={s.textBox}>
                    <img className={s.coinImg} src={'/images/startTasks/coin_blue.webp'} alt="coin" />
                    <p className={s.text}><Trans>Complete all tasks to continue and earn</Trans> <span className={s.textBlue}>2,450 BAA.</span></p>
                </div>

                <ProgressBarStartTasks total={tasks.length} done={doneTasks.length} />
            </div>


            <button className={`${s.btnScreen} ${!completedAllTasks ? s.disabled : s.enabled}`}
                onClick={closeModal}
                disabled={!completedAllTasks}
                type="button">
                <p className={s.screenBtnText} style={{ opacity: `${!completedAllTasks ? 0.75 : 1}` }}><Trans>let`s go</Trans></p>
            </button>
        </div>
    )
}

export default ModalStartTasksContent