import { Trans } from "@lingui/react/macro";
import s from './ModalStartTasksContent.module.css'
import Spinner from "../common/Spinner";

interface ListItemProps {
    title: string
    fontSize: number
    isJoin: boolean
    btnSize: string
    imgBG: string
    onClick: () => void
    isClicked: boolean
    isDisabled: boolean
}

const ListItem: React.FC<ListItemProps> = ({ title, fontSize, isJoin, btnSize, imgBG, onClick, isClicked, isDisabled }) => {
    const itemBG = isJoin ? "/images/startTasks/item_bg_check.webp" : "/images/startTasks/item_bg_tg.webp"
    const btnText = isJoin ? <Trans>done!</Trans> : <Trans>go!</Trans>
    // const btnText = isJoin ? "done!" : "go!"
    const btnBG = isJoin ? "/images/startTasks/btn_green.webp" : "/images/startTasks/btn_blue.webp"
    const textOpacity = isJoin ? 50 : 100

    const textStyle = {
        fontSize: `${fontSize}rem`,
        opacity: `${textOpacity}%`
    };

    return (
        <div className={s.itemContainer}>

            <div className={`${s.itemBG} ${s[imgBG]}`} style={{ backgroundImage: `url(${itemBG})` }}>
                <button className={`${s.btn} ${s[btnSize]}`}
                        style={{ backgroundImage: `url(${btnBG})` }}
                        disabled={isDisabled || isJoin}
                        onClick={onClick}
                        type="button">

                        {isClicked ? <Spinner /> : <p className={s.title}
                    // style={{ fontSize: `${fontSize}rem` }}
                    >{btnText}</p>}
                </button>
            </div>

            <p className={s.title} style={textStyle}><Trans>join</Trans></p>
            <p className={s.title} style={textStyle}>{title}</p>
        </div >
    )
}

export default ListItem