const aboutData = [
    {
        title: "Coins for taps",
        text: "Shear the sheep by tapping on the screen and earn coins!",
        icon: "/images/about/about_coins_shear.webp"
    },
    {
        title: "Refer frens",
        text: "Invite your friends to play! Earn 350 BAA for each active(sheared at least 900 BAA) friend",
        icon: "/images/about/about_refer.webp"
    },
    {
        title: "Climb Leaderboard",
        text: "Climb up the leaderboard to win valuable prizes!",
        icon: "/images/about/about_climb.webp"
    },
]

export default aboutData